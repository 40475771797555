const UnderConstruction = () => {
  return (
    <>
      <div className="flex items-center justify-center h-screen px-4">
        <p>
          We are currently updating the alumni clearance completion process. Please
          check back. we will be back up shortly. Thank you for your patience
        </p>
      </div>
    </>
  );
};

export default UnderConstruction;
